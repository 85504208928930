import React, { useRef, useState } from "react";
import { ICar } from "../../models/ICar";
import Modal from "../Modal";
import CheckBox from "../CheckBox";
import InputField from "../InputField";
import './style.scss'
import ApiUrls from "../../apis/ApiUrls";
import { postApi } from "../../apis/Apis";
import { INews } from "../../models/INews";
const NewsModal = ({
  show, onClose, onConfirm, news = new INews()
}) => {
  const fileRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [imgFile, setImgFile] = useState(null)
  const [_news, setNews] = useState(news)
  const onOpenFile = () => {
    if (uploading) return
    fileRef.current.click()
  }
  const onFileSelect = (e) => {
    setImgFile(e.target.files[0])
  }

  const onSave = async () => {
    var body = new FormData()
    body.append('file', imgFile)
    body.append('title', _news.title)
    if (_news._id) {
      body.append('_id', _news._id)
      body.append('imgPath', _news.imgPath)
      var res = await postApi(`${ApiUrls.editNewsItem}`, body, true)
      onConfirm(true, res.result)
    } else {
      var res = await postApi(`${ApiUrls.addNewsItem}`, body, true)
      onConfirm(false, res.result)
    }
  }
  return (
    <div className='news-modal'>
      <Modal show={show} confirmText='Confirm'
        title='News Item' subTitle='Create news item'
        onClose={onClose} onConfirm={onSave}
        height='480px'
      >
        <div className='news-edit'>
          <img className='news-photo' src={_news.imgPath} onClick={onOpenFile} />
          <div className='inputs'>
            <input
              name="imgPath"
              type="file"
              className='none'
              ref={fileRef}
              onChange={onFileSelect}
            />
            <InputField value={_news.title} label='Title'
              onChange={(e) => {
                setNews((news) => {
                  news.title = e.target.value
                  return new INews(news)
                })
              }} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NewsModal