import React from "react"
import Modal from "../Modal"
import './style.scss'
const AlertModal = ({ show, title, subTitle, onClose, onConfirm }) => {
  return (
    <div className='al-modal'>
      <Modal show={show} title={title} height='240px' width='400px' confirmBackColor='green'
        subTitle={subTitle} onClose={onClose} onConfirm={onConfirm}>
      </Modal>
    </div>
  )
}

export default AlertModal
