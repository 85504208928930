import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import NewsSettings from "../../components/NewsSettings";
import Cars from "../../components/Cars";
import PinsSettings from "../../components/PinsSettings";
import InstallRequest from "../../components/InstallRequest";
import SettingsContainer from "../../containers/SettingsContainer";
import { useSelector } from "react-redux";
import Admins from "../../components/Admins";

const Setting = () => {
  const { role } = useSelector(state => state.mainReducer)
  return (
    <SettingsContainer>
      <BrowserRouter basename='/settings'>
        <Switch >
          <Route exact={true} path='/' component={() => <Redirect to='/images' />} />
          <Route exact path='/news' component={NewsSettings} />
          <Route exact path='/pins' component={PinsSettings} />
          {(role == 0 || role == 2) && (<Route exact path='/admins' component={Admins} />)}
          <Route exact path='/cars' component={Cars} />
          <Route exact path='/insreq' component={InstallRequest} />
        </Switch>
      </BrowserRouter>
    </SettingsContainer>
  )
}

export default Setting