import React, { useEffect, useState } from "react";
import { getApi, postApi } from "../../apis/Apis";
import ApiUrls from "../../apis/ApiUrls";
import './style.scss'
const PinsSettings = () => {
    const [state, setState] = useState({
        creating: false,
        pins: []
    })
    useEffect(() => {
        (async () => {
            const res = await getApi(`${ApiUrls.getPins}`, true)
            if (res.success) {
                _setState('pins', res.pins)
            }
        })()
    }, [])
    const _setState = (field, value) => {
        setState((prevState) => {
            prevState[field] = value
            return { ...prevState }
        })
    }
    const onNew = () => {
        _setState('creating', true)
    }
    const onSave = async (value, amount) => {
        const result = await postApi(`${ApiUrls.createPin}`, { value, amount }, true)
        _setState('creating', false)
        if (result.success) {
            setState((prevState) => {
                prevState.pins = [...result.pins, ...prevState.pins]
                return { ...prevState }
            })
        }
    }
    return (
        <div className='pins-settings'>
            {state.creating ? <PinEdit onCreate={onSave} onClose={()=> {
                _setState('creating', false)
            }} /> : <></>}
            {!state.creating ? <input type='button'
                className='button'
                value={'Create New Pin'}
                onClick={onNew}
            /> : <></>}
            <Pins pins={state.pins}></Pins>
        </div>

    )
}

const PinEdit = ({ onCreate, onClose }) => {

    const [state, setState] = useState({
        value: 0, amount: 0, saving: false, error: ''
    })
    const _setState = (field, value) => {
        setState((prevState) => {
            prevState[field] = value
            return { ...prevState }
        })
    }
    const onValueChange = (e) =>
        _setState('value', parseFloat(e.target.value))

    const onAmountChange = (e) =>
        _setState('amount', parseFloat(e.target.value))

    const onSave = async () => {
        if (state.value < 10 || state.value > 1000)
            return _setState('error', 'Amount should be between 10 and 1000.')
        else if (state.amount < 1 || state.amount > 100)
            return _setState('error', 'Number should be between 1 and 100.')
        _setState('error', '')
        _setState('saving', true)
        await onCreate(state.value, state.amount)
    }
    return (
        <div className='pin-edit'>
            <p>Amount of RM</p>
            <input type='number' min={0} max={1000} value={state.value} className='input' onChange={onValueChange} />
            <p>Numbers of Pin</p>
            <input type='number' min={1} max={1000} value={state.amount} className='input' onChange={onAmountChange} />
            <p className='error'>{state.error}</p>
            <input type='button'
                className='button'
                value={state.saving ? 'Saving' : 'Save'}
                onClick={onSave}
            />
            <input type='button'
                className='button'
                value='Cancel'
                onClick={()=>{
                    if (!state.saving) onClose()
                }}
            />
        </div>
    )
}
const Pins = ({ pins }) => {
    return (
        <div className='pins'>
            <ul>
                {pins ? pins.map((pin, index) =>
                    <li className='pin-row' key={index}>
                        <p className='amount'>Amount: RM {pin.amount}</p>
                        <p >Pin:<span className='pincode'>{pin.pin}</span></p>
                    </li>) : <></>}
            </ul>
        </div>
    )
}

export default PinsSettings