import React, { Component, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { getApi } from './apis/Apis';
import ApiUrls from './apis/ApiUrls';
import AppContainer from './containers/AppContainer';
import { Dashboard } from './pages';
import ResetPwd from './pages/ResetPwd';
import Setting from './pages/Settings';
import actions from './actions'
import { useDispatch, useSelector } from 'react-redux';
const AppMain = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const { role } = useSelector(state => state.mainReducer)
  useEffect(() => {
    (async () => {
      const res = await getApi(`${ApiUrls.loginjwt}`, true)
      setLoading(false)
      if (res.success) {
        if (!res.active) {
          localStorage.setItem('token', '')
          window.open('/', '_self')
        }
        else {
          localStorage.setItem('token', res.token)
          dispatch(actions.setAdminRole(res.role))

        }
      } else {
        localStorage.setItem('token', '')
      }
    })()
  }, [])
  return (
    localStorage.getItem('token') && !loading ?
      <AppContainer>
        <Switch>
          {(role == 0 || role == 2) && <Route path='/settings' component={Setting} />}
          <Route exact path='/dashboard' component={() => <Redirect to='/dashboard/users' />} />
          <Route exact path='/dashboard/users' component={Dashboard} />
          <Route exact path='/change-password' component={ResetPwd} />
        </Switch>
      </AppContainer> :
      !loading ?
        !localStorage.getItem('token') && <Redirect to='/' /> :
        <div className='loading'></div>

  )
}

export default AppMain