import React, { Component, useState } from "react";
import { Redirect } from "react-router";
import { postApi } from "../../apis/Apis";
import Button from '../../components/Button';

import ApiUrls from "../../apis/ApiUrls";
import './style.scss'
const ResetPwd = () => {

    const [oldPwd, setOldPwd] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const [newPwdCon, setNewPwdCon] = useState('')
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const [msgNewPwd, setMsgNewPwd] = useState('')
    const onChange = async () => {

        if (newPwd != newPwdCon) return setMsgNewPwd('Password Mismatch')
        else {
            setMsgNewPwd('')
        }
        setLoading(true)
        setMsg('Changin Password...')
        const res = await postApi(`${ApiUrls.changePwd}`, { oldPwd, newPwd }, true)
        setLoading(false)
        setMsg('')
        if (res.success) {
            localStorage.setItem('token', '')
            window.location.replace('/')
        } else {
            setMsg(res.error)
        }
    }
    return (

        <div className='resetpwd-screen'>
            <div className='resetpwd-form'>
                <p className='title'>
                    Change Password
                </p>
                <p className='msg'>{msg}</p>
                <div className='inputs-field'>
                    <p className='text'>Current Password</p>
                    <input className='input' placeholder='Current Password' type='password' value={oldPwd} onChange={(e) => setOldPwd(e.target.value)} />
                    <p className='text'>New Password</p>
                    <p className='msg'>{msgNewPwd}</p>
                    <input className='input' placeholder='New Password' type='password' value={newPwd} 
                    onChange={(e) => {
                        setMsgNewPwd(e.target.value != newPwdCon ? 'Password Mismatch' : '')
                        setNewPwd(e.target.value)}} />

                    <input className='input' placeholder='Confirm New Password' type='password'
                        onChange={(e) => {
                            setMsgNewPwd(e.target.value != newPwd ? 'Password Mismatch' : '')
                            setNewPwdCon(e.target.value)
                        }} />

                    {loading ? <></> : <Button className='button' title='Confirm' onClick={onChange} />}
                </div>
            </div>
        </div>
    )
}

export default ResetPwd