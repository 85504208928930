import React, { useEffect, useRef } from "react"
import Button from '../Button'
import './style.scss'
const Modal = (props) => {
  const modalRef = useRef(null);
  return (
    <div ref={modalRef} className={`modal-wrap ${props.show ? 'active' : ''}`}>
      <div className='modal'>
        <div className='modal-header'>
          <p className='title'>{props.title}</p>
          <p className='subtitle'>{props.subTitle}</p>
        </div>
        <div className='body'>
          {props.children}
        </div>
        <div className='footer'>
          <Button title={props.cancelText || 'Cancel'}
            onClick={props.onClose} />
          {!props.hideConfirm && <Button title={props.confirmText || 'Confirm'}
            invalid={props.confirmInvalid}
            onClick={props.onConfirm}
            backgroundColor={props.confirmBackColor}
            borderColor={props.confirmBackColor}
          />}
        </div>
      </div>
    </div>
  )
}

export default Modal