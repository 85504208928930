export class ICar {
  constructor(data =
    {
      _id: null,
      brand: '',
      location: 'Klang valley',
      imgPath: '',
      price: 0,
      featured: false
    }) {
    this._id = data._id;
    this.brand = data.brand;
    this.location = data.location;
    this.imgPath = data.imgPath;
    this.price = data.price
    this.featured = data.featured
  }
}