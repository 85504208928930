import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AppMain from './AppMain';
import { Login} from './pages'
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path='/' component={() => <Redirect to='/login' />} />
        <Route exact={true} path='/login' component={Login} />
        <Route component={AppMain} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
