import { React, useEffect, useState } from 'react'
import { MenuIcon } from "@heroicons/react/solid";
import logo from '../../assets/imgs/logo.png'
import './style.scss'
import { Menu } from '@headlessui/react';
import Avatar from '../Avatar';
import constants from '../../app/constant'
import { useSelector } from 'react-redux';
const AppHeader = () => {

  const onLogout = async () => {
    localStorage.setItem('token', '')
    window.location.reload()
  }
  const { role } = useSelector(state => state.mainReducer)

  return (
    <div className='app-header'>
      <a href='/'><img className='logo' src={logo} alt='' /></a>
      <div className='profile-menu' >
        <Menu>
          <Menu.Button className='trigger-container' >
            <Avatar src={constants.defaultAvatar} />
          </Menu.Button>
          <Menu.Items className='setting-menu'>
            <ul>
              {role != 1 &&
                <li>
                  <a className='setting-link' href={`/settings`}>Setting</a>
                </li>}
              {role != 1 &&
                <li>
                  <a className='setting-link' href={`/change-password`}>Change Password</a>
                </li>}

              <li>
                <div className='setting-link' onClick={onLogout}>Log out</div>
              </li>
            </ul>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  )
}
export default AppHeader