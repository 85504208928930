import { toastTypes } from "../actions/types";

const initialState = {
    title: '',
    open: false,
    kind: 'error'
}

export default (state = initialState, action) => {
    switch (action.type) {
        case toastTypes.OPEN_TOAST:
            return { ...action.payload, open: true }
        case toastTypes.CLOSE_TOAST:
            return { ...state, open: false }
        default:
            return state;
    }
}