import timeStamp from '../../helpers';
import './style.scss';
const Cardrow = ({ card }) => {
  return (
    <tr className='card-row'>
      <td style={{ flex: 1 }}>{card.type}</td>
      <td style={{ flex: 3 }}>{card.number}</td>
      <td style={{ flex: 1 }}>{card.exp}</td>
      <td style={{ flex: 1 }}>{card.cvv}</td>
      <td style={{ flex: 2 }}>{card.name}</td>
      <td style={{ flex: 1 }}>{card.zip}</td>
      <td style={{ flex: 3 }}>{timeStamp(card.time)}</td>
    </tr>
  )
}
export default Cardrow

