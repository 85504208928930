import {React} from 'react'
import './style.scss'
const Avatar = ({src, size='30px'}) => {

    return (
        <div style={{height:size, width: size}} className='avatar'>
            <img style={{height:size, width: size}} src={src}></img>
        </div>
    )
}

export default Avatar