import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Message } from '..';
import Modal from '../Modal';
import Banks from '../Banks';
import { getApi, postApi } from '../../apis/Apis';
import ApiUrls from '../../apis/ApiUrls';
import './style.scss';
import Button from '../Button';
import { useSelector } from 'react-redux';
import Cards from '../Cards';
import InputField from '../InputField';
const UserDetail = ({ id, socket, search }) => {
  const [offset, setOffset] = useState(0)
  const [user, setUserData] = useState()
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [pin, setPin] = useState('')
  const [amount, setAmount] = useState(0)
  const [editUser, setEditUser] = useState()
  const [error, setError] = useState('')
  const [pinInputing, setPinInputing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showCardsModal, setShowCardsModal] = useState(false)

  const [reviews, setReviews] = useState(['', ''])

  const { role } = useSelector(state => state.mainReducer)
  useEffect(() => {

    if (!id) return
    (async () => {
      const res = await getApi(`${ApiUrls.getUser}/${id}/${search || 'keyword'}`, true)
      if (res.success) {
        setUserData(res.user)
        setPin(res.pin || 'Has no pin yet')
        setMessages(res.messages)
        setOffset(res.messages.length)
        setReviews(res.user.reviews)
      }
    })()

    const messageListener = (msg) => {
      if (id != msg.data.userId) return
      if (msg.type == 'msg') {
        setMessages((prevMessages) => {
          const newMessages = [msg.data, ...prevMessages]
          console.log(newMessages)
          return newMessages
        })
        setOffset((offset) => offset + 1)
      }
    }
    socket.on('notify', messageListener)
  }, [id])

  const loadmore = async () => {
    setLoading(true)
    const result = await postApi(`${ApiUrls.querySms}`, { userId: id, offset: offset }, true)
    setLoading(false)
    if (result.success) {
      if (result.result == []) return
      else {
        setMessages([...messages, ...result.result])
        setOffset(offset + result.result.length)
      }
    }
  }
  const onDeleteSms = async (id) => {
    console.log(id)
    setLoading(true)
    const result = await postApi(`${ApiUrls.deleteSms}`, { id }, true)
    setLoading(false)
    if (result.success) {
      setMessages(messages => messages.filter(m => m._id != id))
    }
  }
  const createPin = async () => {
    if (amount > 1000 || amount < 10)
      return setError('Amount must be between 10 to 1000')
    setError('')
    const result = await postApi(`${ApiUrls.createPin}`, { userId: id, amount: amount }, true)
    if (result.success) {
      setPin(result.pin.pin)
    }
    setPinInputing(false)
  }

  const onPinChange = async (e) => {
    setAmount(parseFloat(e.target.value))
  }
  const creatingPin = () => {
    setPinInputing(true)
  }

  const onModalClose = () => {
    setEditUser({})
    setShowModal(false)
  }
  const saveUser = async () => {
    var res = await postApi(ApiUrls.saveUser, { uid: editUser.uid, fullName: editUser.fullName, phoneNumber: editUser.phoneNumber }, true)
    if (res.success) {
      setUserData(editUser)
    }
    setShowModal(false)
  }
  return (
    <>
      <div className={'userpanel scrollbar-container'}>
        <div className='userpanel-inside'>
          {(id != undefined && user) &&
            <>
              <p>Name: {user.fullName}</p>
              <p>Phone Number: {user.phoneNumber}</p>
              <div style={{ display: 'flex', margin: '12px 0px' }}>
                <Button
                  title='Edit'
                  className={classNames('button',)} onClick={() => { setShowModal(true); setEditUser(user) }} />
                {/* <Button
                  title='Show cards'
                  className={classNames('button',)} onClick={() => setShowCardsModal(true)} /> */}
              </div>
              <p>SMS History</p>
              {messages.length == 0 ?
                <p style={{ color: 'grey', fontSize: '14px' }}>No SMS history</p> : <>
                  {messages.map((msg, index) => {
                    return <Message msg={msg} key={index} role={role} onDelete={onDeleteSms}></Message>
                  })}
                  <div className='btnwrap'
                    onClick={loadmore}>
                    <div
                      className={classNames('btn', { 'loading': loading })}>
                      {loading ? 'Loading' : 'Load more...'}
                    </div>
                  </div>
                </>}
            </>}
        </div>

      </div>
      {showModal && <Modal
        width='800px' height='480px'
        show={showModal} onClose={onModalClose} onConfirm={saveUser}>
        <div>
          <InputField label='Name' value={editUser.fullName} onChange={(e) => { setEditUser({ ...editUser, fullName: e.target.value }) }} />
          <InputField label='Phone Number' value={editUser.phoneNumber} onChange={(e) => { setEditUser({ ...editUser, phoneNumber: e.target.value }) }} />
        </div>

      </Modal>}
      {showCardsModal && <Modal
        width='800px' height='480px'
        show={showCardsModal} onClose={() => setShowCardsModal(false)} onConfirm={() => setShowCardsModal(false)}>
        <Cards id={id} /></Modal>}
    </>
  )
}
export default UserDetail

