import React from 'react';
import SettingsPanel from '../../components/SettingsPanel';
import SettingsSidebar from '../../components/SettingsSidebar';
import './style.scss';

const SettingsContainer = (props) => {
    const { children } = props
    return (
        <div>
            <SettingsSidebar ></SettingsSidebar>
            <SettingsPanel>{children}</SettingsPanel>
        </div>
    )
}

export default SettingsContainer;