import React, { Component, useState } from "react";
import { Redirect } from "react-router";
import { postApi } from "../../apis/Apis";
import ApiUrls from "../../apis/ApiUrls";
import './style.scss'
const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const [token, setToken] = useState(localStorage.getItem('token'))
    // const token = localStorage.getItem('token')
    const onLogin = async () => {
        setLoading(true)
        setMsg('Logging in...')
        const res = await postApi(`${ApiUrls.login}`, { email, password })
        setLoading(false)
        setMsg('')
        console.log(res)
        if (res.success) {
            localStorage.setItem('token', res.token)
            setToken(res.token)
        } else {
            setMsg(res.error)
        }
    }
    return (
        token && !loading ? <Redirect to='/dashboard' /> :
            <div className='login-screen'>
                <div className='login-form'>
                    <p className='title'>
                        Omni
                    </p>
                    <p className='msg'>{msg}</p>
                    <div className='inputs-field'>
                        <input className='input' placeholder='Email' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input className='input' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />

                        {loading ? <></> : <input type='button' className='button' value='Login' onClick={onLogin} />}
                    </div>
                </div>
            </div>
    )
}

export default Login