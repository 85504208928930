import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import './style.scss';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import InputField from '../InputField';
const Sidebar = ({ users, selected, search, socket, onUserSelected, onSetKeyword }) => {
  const sortUsers = (users) =>
    users.sort((a, b) =>
      a.unread > b.unread ? -1 :
        a.unread == b.unread ?
          a.createdAt > b.createdAt ? -1 : 1 : 1)

  const [currentUsers, setUsers] = useState(sortUsers(users))
  const [keyword, setKeyword] = useState(search || '')
  const { role } = useSelector(state => state.mainReducer)
  useEffect(() => {
    setUsers(users)
    const messageListener = (msg) => {
      if (msg.type == 'msg') {
        const index = currentUsers.findIndex((user) => user._id == msg.data.userId)
        console.log(index)
        var updatedUsers
        if (index < 0) {
          updatedUsers = [...currentUsers, { unread: 1, phoneNumber: '', uid: msg.data.uid, _id: msg.data.userId, createdAt: moment.now() }]
        } else {
          updatedUsers = currentUsers.map((user, index) => {
            if (user._id == msg.data.userId) user.unread++
            return user
          })
        }
        setUsers(sortUsers(updatedUsers))
      }
    }
    socket.on('notify', messageListener)

  }, [socket])
  const onFilter = (e) => {
    setKeyword(e.target.value)
    onSetKeyword(e.target.value)
  }


  return (
    <div className={'sidebar scrollbar-container'}>
      <p style={{ fontWeight: 'bold' }}>USERS LIST</p>
      <div className='search-wrap'>
        {/* <input type='text' value={keyword} className='input' onChange={onFilter} /> */}
        <InputField value={keyword} placeholder='Search Phone Number' onChange={onFilter} />
      </div>
      <div className={'list'}>
        {<ul >
          {currentUsers?.map((user, index) =>
            (user.phoneNumber.indexOf(keyword) > -1) ?
              <li className={classNames({ 'selected': selected == user._id }, 'user')}
                key={index}>
                <span style={{ position: 'absolute', top: '0px', left: '0px' }}
                  className={classNames('badge danger round', { 'zero': user.unread == 0 })}>{user.unread}</span>
                <a href={`/dashboard/users?id=${user._id}&keyword=${keyword}`} key={index} className='user'
                  onClick={e => { e.preventDefault(); onUserSelected(user._id) }}>
                  <div>
                    UID: {user.uid}
                  </div>
                  <div className='badges'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Phone Number: {user.phoneNumber}
                    </div>

                  </div>
                </a>
              </li> : <></>)}
        </ul>}
      </div>
    </div>
  )
}



export default Sidebar

