import { getApi } from "../../apis/Apis";
import ApiUrls from "../../apis/ApiUrls";
import { Sidebar, UserDetail } from "../../components";
import queryString from 'query-string';
import io from 'socket.io-client'
import React, { Component, useEffect, useState } from 'react';

const Dashobard = (props) => {
  const [users, setUsers] = useState([])
  const [socket, setSocket] = useState(null)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [id, setId] = useState(queryString.parse(props.location.search).id || '')

  const search = queryString.parse(props.location.search).keyword
  useEffect(async () => {
    setLoading(true)
    let newSocket
    if (window.location.origin.indexOf('http://localhost') >= 0)
      newSocket = io(`http://localhost:3001`)
    else
      newSocket = io(`${window.location.origin}`)

    setSocket(newSocket)
    const users = await getApi(`${ApiUrls.getUsers}/${id}`, true)
    setUsers(users.users)
    setLoading(false)
    return () => newSocket.close();
  }, [])
  return (
    <>
      {loading ? <></> :
        socket ? <Sidebar
          onSetKeyword={k => setKeyword(k)}
          onUserSelected={id => { setId(id); window.history.replaceState('', '', `/dashboard/users?id=${id}&keyword=${keyword}`) }}
          users={users} selected={id} search={search} socket={socket}></Sidebar> : <></>}
      {socket ? <UserDetail id={id} search={search} socket={socket}></UserDetail> : <></>}

    </>
  )
}

export default Dashobard