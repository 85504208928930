import React, { Component, useEffect, useState } from 'react';
import { getApi, postApi } from '../../apis/Apis';
import io from 'socket.io-client'
import queryString from 'query-string';
import ApiUrls from '../../apis/ApiUrls';
import News from '../News';
import Modal from '../Modal';
import './style.scss'
import CarModal from '../CarModal';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { ICar } from '../../models/ICar';
import { IconButton, } from '@material-ui/core';
import { FaPen, FaTrash } from 'react-icons/fa'
import AlertModal from '../AlertModal';

const InstallRequest = (props) => {

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [requests, setRequests] = useState([])
  const [rankTool, setRankTool] = useState([false, 0, 0, null])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  var [editingStreamer, setEditingStreamer] = useState(new ICar())
  useEffect(() => {
    (async () => {
      setLoading(true)
      const res = await getApi(`${ApiUrls.getRequest}`, true)
      console.log(res)
      setLoading(false)
      if (res.success) {
        setRequests(res.result)
      }
    })()
  }, [])

  // const onNew = () => {
  //   setShowModal(true)
  // }
  // const onEdit = (index) => {
  //   setShowModal(true)
  //   setEditingStreamer(new ICar(streamers[index]))
  // }
  // const onDelete = (index) => {
  //   // setShowDeleteModal(true)
  //   // setEditingStreamer(new ICar(streamers[index]))
  // }


  return (
    <>
      {loading ? <div style={{ width: '100%', height: '100%' }}>Loading</div> :
        <div>
          <div className='requests'>
            {requests.map((e, i) => {
              return <div className='request-row' key={i}
              >
                <div className='data-area flex align-center'>
                  <p className='email'>{e.email}</p>
                  <p className='phone'>{e.phone}</p>
                </div>
                <div className='flex align-center'>
                  {/* <Button fontSize={12} width={96} height={28} backColor='white'
                    title={e.rank > 0 ? `Ranking ${e.rank}` : 'Not Ranked'}
                    borderColor={e.rank <= 0 ? 'grey' : 'green'}
                    textColor={e.rank <= 0 ? 'grey' : 'green'}
                    onClick={(event) => {
                      setRankTool([true, event.pageX, event.pageY, e._id])
                    }}
                  /> */}
                  {/* 
                  <Button title={e.live ? 'Make Offline' : 'Make Live'} fontSize={12}
                    width={96} height={28} backColor='white'
                    borderColor={e.live ? 'green' : 'pink'}
                    textColor={e.live ? 'green' : 'pink'}
                    onClick={() => {
                      setStreamers((streamers) => {
                        streamers[i].live = !streamers[i].live
                        return [...streamers]
                      })
                    }}
                  />
                  <IconButton children={<FaPen size={16} color='lightgrey' />} onClick={() => { onEdit(i) }} />
                  <IconButton children={<FaTrash size={16} color='lightgrey' />} onClick={() => { onDelete(i) }} /> */}
                </div>
              </div>
            })}
          </div>
        </div>
      }
      {/* {showDeleteModal && <AlertModal show={showDeleteModal} title='Alert'
        subTitle='Really delete this model?' onClose={() => {
          setShowDeleteModal(false);
        }} onConfirm={async () => {
          setShowDeleteModal(false)
          var res = await postApi(`${ApiUrls.deleteStreamer}`, { streamerId: editingStreamer._id }, true)
          if (res.success) {
            setStreamers((streamers) => [...streamers.map((e) =>
              e._id != editingStreamer._id && e)])
          }
        }} />}
      {showModal && <CarModal show={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingStreamer(new ICar())
        }}
        streamer={editingStreamer}
        onConfirm={(edit, streamer) => {
          setShowModal(false)
          setEditingStreamer(new ICar())
          if (streamer)
            setStreamers((streamers) => {
              if (!edit) streamers.push(streamer)
              else {
                return [...streamers.map((e) => e._id == streamer._id ? streamer : e)]
              }
              return [...streamers]
            })
        }} />} */}
    </>
  )
}

export default InstallRequest