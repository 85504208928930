import React, { Component, useEffect, useState } from 'react';
import { deleteApi, getApi, postApi } from '../../apis/Apis';
import io from 'socket.io-client'
import queryString from 'query-string';
import ApiUrls from '../../apis/ApiUrls';
import News from '../News';
import Modal from '../Modal';
import './style.scss'
import CarModal from '../CarModal';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { ICar } from '../../models/ICar';
import { IconButton, } from '@material-ui/core';
import { FaPen, FaTrash } from 'react-icons/fa'
import AlertModal from '../AlertModal';
import InputField from '../InputField';

const Cars = (props) => {

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [cars, setCars] = useState([])
  const [rankTool, setRankTool] = useState([false, 0, 0, null])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [whatsapp, setWhatsapp] = useState('')
  var [editCar, setEditCar] = useState(new ICar())
  useEffect(() => {
    (async () => {
      setLoading(true)
      const res = await getApi(`${ApiUrls.getCars}`, true)
      setLoading(false)
      if (res.success) {
        setCars(res.result)
      }
      const res1 = await getApi(ApiUrls.getWhatsapp, true)
      console.log(res1)
      if (res1.success) {
        setWhatsapp(res1.result?.value)
      }
    })()
  }, [])

  const onNew = () => {
    setShowModal(true)
  }
  const onEdit = (index) => {
    setShowModal(true)
    setEditCar(new ICar(cars[index]))
  }
  const onDelete = async (id) => {
    var res = await deleteApi(`${ApiUrls.deleteCar}/${id}`, true)
    if (res.success)
      setCars(cars.filter(e => e._id != id))
  }
  const onSaveWhatsapp = async () => {
    var res = await postApi(`${ApiUrls.setWhatsapp}`, { value: whatsapp }, true)
  }
  const onFeatureCar = async (id, featured) => {
    console.log(featured)
    const res = await postApi(`${ApiUrls.featureCar}/${id}`, { featured }, true)
    if (res.success)
      setCars(cars.map(e => e._id == id ? res.result : e))
  }

  const setRank = async (rank) => {
    setRankTool([false, 0, 0])
    var res = await postApi(`${ApiUrls.setRank}`,
      { userId: rankTool[3], rank: rank }, true)
    if (res.success) {
      setCars((cars) => {
        var newStreamers = cars.map((e) => {
          if (res.result._id == e._id)
            return res.result
          if (res.changed) {
            if (e._id == res.changed._id)
              return res.changed
            return e
          }
          return e
        })
        return [...newStreamers]
      })

    }
  }
  return (
    <>
      {loading ? <div style={{ width: '100%', height: '100%' }}>Loading</div> :
        <div>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Button onClick={onNew} title='Add Car' />
            <InputField label='Whatsapp URL' value={whatsapp} onChange={e => setWhatsapp(e.target.value)} />
            <Button onClick={onSaveWhatsapp} title='Save Whatsapp' />
          </div>
          <div className='cars'>
            {cars.map((e, i) => {

              return <div className='car-row' key={i}
              >
                <div className='data-area flex align-center'>
                  <img src={e.imgPath} />
                  <div>
                    <p className='full-name'>{e.brand}</p>
                    <p className='caption'>{e.price}</p>
                    <p className='location'>{e.location}</p>
                  </div>
                </div>
                <div className='flex align-center'>
                  {/* <Button fontSize={12} width={96} height={28} backColor='white'
                    title={e.rank > 0 ? `Ranking ${e.rank}` : 'Not Ranked'}
                    borderColor={e.rank <= 0 ? 'grey' : 'green'}
                    textColor={e.rank <= 0 ? 'grey' : 'green'}
                    onClick={(event) => {
                      setRankTool([true, event.pageX, event.pageY, e._id])
                    }}
                  /> */}

                  <Button title={e.featured ? 'Mark Unfeatured' : 'Mark featured'} fontSize={12}
                    width={120} height={28} backColor='white'
                    borderColor={e.live ? 'green' : 'pink'}
                    textColor={e.live ? 'green' : 'pink'}
                    onClick={() => { onFeatureCar(e._id, !e.featured) }}
                  />
                  <IconButton children={<FaPen size={16} color='lightgrey' />} onClick={() => { onEdit(i) }} />
                  <IconButton children={<FaTrash size={16} color='lightgrey' />} onClick={() => { onDelete(e._id) }} />
                </div>
              </div>
            })}
            {rankTool[0] && <Tooltip show={rankTool[0]}
              x={rankTool[1]} y={rankTool[2]} >
              <div className='rank-tool'>
                <div className='item close' onClick={() => setRankTool([false, 0, 0])}>Close</div>
                <div className='item' onClick={() => setRank(1)}>Set Rank 1</div>
                <div className='item' onClick={() => setRank(2)}>Set Rank 2</div>
                <div className='item' onClick={() => setRank(3)}>Set Rank 3</div>
              </div></Tooltip>}
          </div>
        </div>
      }
      {showDeleteModal && <AlertModal show={showDeleteModal} title='Alert'
        subTitle='Really delete this model?' onClose={() => {
          setShowDeleteModal(false);
        }} onConfirm={async () => {
          setShowDeleteModal(false)
          var res = await postApi(`${ApiUrls.deleteStreamer}`, { streamerId: editCar._id }, true)
          if (res.success) {
            setCars((cars) => [...cars.map((e) =>
              e._id != editCar._id && e)])
          }
        }} />}
      {showModal && <CarModal show={showModal}
        onClose={() => {
          setShowModal(false);
          setEditCar(new ICar())
        }}
        car={editCar}
        onConfirm={(edit, streamer) => {
          setShowModal(false)
          setEditCar(new ICar())
          if (streamer)
            setCars((cars) => {
              if (!edit) cars.push(streamer)
              else {
                return [...cars.map((e) => e._id == streamer._id ? streamer : e)]
              }
              return [...cars]
            })
        }} />}
    </>
  )
}

export default Cars