import './style.scss'
const InputField = (props) => {

  return (
    <div className="input-field" style={{ width: props.width ?? '240px' }}>
      <p className='label'>{props.label}</p>
      <input type={props.type || 'text'} style={{ height: props.height ?? '32px', padding: props.padding ?? '8px' }}
        value={props.value} placeholder={props.placeholder}
        onChange={props.onChange} />
    </div>)
}

export default InputField