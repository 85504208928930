import { React } from "react";
import { useSelector } from "react-redux";
import './style.scss'
const SettingsSidebar = () => {
  const { role } = useSelector(state => state.mainReducer)

  const url = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
  return (
    <div className={'settings-sidebar scrollbar-container'}>
      <p>Settings</p>
      <div className={'list'}>
        <ul >
          <li className={(url == 'settings' || url == 'news') ? 'selected' : ''}>
            <a href={`/settings/news`}  >
              News Feeds
            </a>
          </li>
          {/* <li className={url == 'pins' ? 'selected' : ''}>
            <a href={`/settings/pins`}  >
              Pins
            </a>
          </li> */}
          {
            (role == 0 || role == 2) && (
              <li className={url == 'admins' ? 'selected' : ''}>
                <a className='setting-link' href={`/settings/admins`}>Admins</a>
              </li>
            )}
          <li className={url == 'cars' ? 'selected' : ''}>
            <a href={`/settings/cars`}  >
              Cars
            </a>
          </li>
          {/* <li className={url == 'insreq' ? 'selected' : ''}>
            <a href={`/settings/insreq`}  >
              Install Request
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  )
}
export default SettingsSidebar