import React, { useEffect, useState } from 'react';
import { getApi } from '../../apis/Apis';
import ApiUrls from '../../apis/ApiUrls';
import Cardrow from './cardrow';
import './style.scss';
const Cards = ({ id }) => {
  const [cards, setCards] = useState([])
  useEffect(() => {
    (async () => {
      var res = await getApi(`${ApiUrls.getCards}/${id}`, true)
      if (res.success) setCards(res.cards)
    })()
  }, [])

  return (
    <div className='cards'>
      {cards?.length > 0 ?
        <table style={{ width: '100%' }}>
          <th className='card-header' style={{ width: '100%' }}>
            <td style={{ flex: 1 }}>Type</td>
            <td style={{ flex: 3 }}>Number</td>
            <td style={{ flex: 1 }}>Exp</td>
            <td style={{ flex: 1 }}>CVV</td>
            <td style={{ flex: 2 }}>Holder Name</td>
            <td style={{ flex: 1 }}>ZIP</td>
            <td style={{ flex: 3 }}>Time</td>
          </th>
          <tbody style={{ width: '100%' }}>
            {cards?.map((card, index) => <Cardrow card={card}></Cardrow>)}
          </tbody>
        </table> :
        <div>Empty Cards</div>
      }
    </div>
  )
}
export default Cards

