import moment from "moment"

const timeStamp = (time) => {

  const now = moment()
  const diff = now.diff(time, 'seconds')
  if (diff < 60) return `${diff} secs ago`
  else if (60 <= diff && diff < 3600) return `${parseInt(diff / 60)} mins ago`
  else if (3600 <= diff && diff < 86400) return `${parseInt(diff / 3600)} hours ${parseInt(diff % 3600 / 60)} mins ago`
  else return moment(time).format('YYYY-MM-DD HH:mm')
}

export default timeStamp