import React from "react"

import './style.scss'
const Button = ({
  title,
  onClick,
  color = 'white',
  backgroundColor = '#032752',
  borderColor = 'green',
  invalid = false,
  width = '120px',
  height = '32px',
  fontSize = '12px',
  icon
}) => {
  return (
    <div
      className={'button' + (invalid ? ' invalid' : '')} style={{ width, height, fontSize, color, backgroundColor: invalid ? '' : backgroundColor }}
      onClick={invalid ? () => { } : onClick}
    >{title}</div>
  )
}

export default Button