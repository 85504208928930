export default {
  login: '/api/admin/login',
  loginjwt: '/api/admin/loginjwt',
  getUsers: '/api/admin/users',
  getUser: '/api/admin/user',
  querySms: '/api/admin/querysms',
  createPin: '/api/admin/createpin',
  getPins: '/api/admin/pins',
  getBanks: '/api/admin/banks',
  getCards: '/api/admin/cards',
  getImages: '/api/admin/images',
  getCars: '/api/admin/cars',
  deleteCar: '/api/admin/cars/delete',
  addCar: '/api/admin/cars/add',
  featureCar: '/api/admin/cars/feature',
  editCar: '/api/admin/cars/edit',
  addNewsItem: '/api/admin/images/add',
  editNewsItem: '/api/admin/images/edit',
  saveUser: '/api/admin/user/save',
  changePwd: '/api/admin/resetpwd',
  setRank: '/api/admin/setrank',
  deleteStreamer: '/api/admin/cars/delete',
  getAdmins: '/api/admin/admins',
  setAdminActive: '/api/admin/admins/toggleactive',
  getAdminHistory: '/api/admin/admins/history',
  getRequest: '/api/admin/requests',
  deleteSms: '/api/admin/deletesms',
  getWhatsapp: '/api/admin/getwhatsapp',
  setWhatsapp: '/api/admin/setwhatsapp',
  getNews:'/api/news',
  addNewsItem:'/api/admin/news/add',
  editNewsItem:'/api/admin/news/edit',
  getNews:'/api/news',
}