import React, { Component, useEffect, useState } from 'react';
import NewsModal from '../NewsModal';
import './style.scss';
const News = ({ news, onClick }) => {
  const [show, setShow] = useState(false)
  return (
    <div onClick={onClick} className='news-card'>
      {!news.imgPath ? <p className='img'>+</p> : <img className='img' src={news.imgPath} />}
      <p className='title'>{news.name}</p>
      <p className='descriptions'>{news.description}</p>

      <NewsModal show={show} />
    </div>
  )
}
export default News

