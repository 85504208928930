import React, { Component, useEffect, useState } from 'react';
import { getApi, postApi } from '../../apis/Apis';
import io from 'socket.io-client'
import queryString from 'query-string';
import ApiUrls from '../../apis/ApiUrls';
import News from '../News';
import Modal from '../Modal';
import './style.scss'
import NewsModal from '../NewsModal';
import { INews } from '../../models/INews';
const NewsSettings = (props) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [news, setNews] = useState([])
  const [editingNews, setEditingNews] = useState(new INews())
  useEffect(() => {
    (async () => {
      setLoading(true)
      const res = await getApi(`${ApiUrls.getNews}`, true)
      setLoading(false)
      if (res.success) {
        setNews(res.result)
      }
    })()
  }, [])

  const onEdit = (news) => {
    setShowModal(true)
    setEditingNews(news)
  }
  return (
    <>
      {loading ? <div style={{ width: '100%', height: '100%' }}>Loading</div> :
        <div>
          <div className='news'>
            {news.map((e, i) =>
              <News key={i} news={e} onClick={() => {
                onEdit(e)
              }}></News>)}
            <News news={new INews()} onClick={() => setShowModal(true)} />
          </div>
        </div>
      }
      {showModal &&
        <NewsModal show={showModal} news={editingNews}
          onClose={() => { setShowModal(false) }}
          onConfirm={(edit, news) => {
            setShowModal(false);
            setEditingNews(new INews())
            if (news)
              setNews((newss) => {
                if (!edit) newss.push(news)
                else {
                  return [...newss.map((e) => e._id == news._id ? news : e)]
                }
                return [...newss]
              })
          }} />}
    </>
  )
}

export default NewsSettings