import React, { useRef, useState } from "react";
import { ICar } from "../../models/ICar";
import Modal from "../Modal";
import CheckBox from "../CheckBox";
import InputField from "../InputField";
import './style.scss'
import ApiUrls from "../../apis/ApiUrls";
import { postApi } from "../../apis/Apis";
import Dropdown from "../Dropdown";
const CarModal = ({
  show, onClose, onConfirm, car = new ICar()
}) => {

  const locations = [
    'Klang valley',
    'Kedah',
    'Perak',
    'Kelantan',
    'Kuantan',
    'Terengganu',
    'Melaka',
    'Johor',
    'Penang',
    'Perlis',
    'Kota Kinabalu',
    'Sandakan',
    'Kuching',
    'Miri',
    'Bintulu',
    'Sibu',
    'Langkawi'
  ]
  const fileRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [imgFile, setImgFile] = useState(null)
  const [_car, setCar] = useState(car)
  const onOpenFile = () => {
    if (uploading) return
    fileRef.current.click()
  }
  const onFileSelect = (e) => {
    setImgFile(e.target.files[0])
  }

  const onSave = async () => {
    var body = new FormData()
    body.append('file', imgFile)
    body.append('location', _car.location)
    body.append('brand', _car.brand)
    body.append('price', _car.price)
    if (_car._id) {
      body.append('id', _car._id)
      body.append('imgPath', _car.imgPath)
      var res = await postApi(`${ApiUrls.editCar}`, body, true)
      onConfirm(true, res.result)
    } else {
      var res = await postApi(`${ApiUrls.addCar}`, body, true)
      onConfirm(false, res.result)
    }
  }
  return (
    <div className='str-modal'>
      <Modal show={show} confirmText='Confirm'
        title='Car' subTitle='Create new Car'
        onClose={onClose} onConfirm={onSave} height='600px'
      >
        <div className='str-edit'>
          <div className='flex'>
            <img className='str-photo' src={_car.imgPath} onClick={onOpenFile} />
            <div className='inputs'>
              <input
                name="imgPath"
                type="file"
                className='none'
                ref={fileRef}
                onChange={onFileSelect}
              />

            </div>
            <div>
              <InputField value={_car.brand} label='Brand' onChange={(e) => setCar({ ..._car, brand: e.target.value })} />
              <InputField value={_car.price} label='Price' onChange={(e) => setCar({ ..._car, price: e.target.value })} type='number' />
              <Dropdown value={_car.location} items={locations.map(e => { return { value: e, text: e } })}
                onChange={(ev) => setCar({ ..._car, location: ev.target.value })} />
            </div>
          </div>
          {/* <input type='button' className='button' value='save' /> */}
        </div>
      </Modal>
    </div>
  )
}

export default CarModal