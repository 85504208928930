import React, { useEffect } from "react"
import './style.scss'
const Tooltip = (props) => {

  useEffect(() => {

  }, [])

  const makeStyle = () => {
    return {
      '--left': `${props.x}px`,
      '--top': `${props.y}px`
    }
  }
  return (
    <div className={`tooltip ${props.show ? 'active' : ''}`}
      style={makeStyle()}
    >
      {props.children}
    </div>
  )
}

export default Tooltip