import React, { Component, useEffect, useState } from 'react';
import './style.scss';
import Button from '../Button';
import classNames from 'classnames';
import timeStamp from '../../helpers';
const Message = ({ msg, role, onDelete }) => {
  return (
    <div>
      <div className={'message'}>
        <div className='phone' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span className='from'>from</span>
            <p className='addr'>{msg.from}</p>
            <p className='addr'>{timeStamp(msg.time)}</p>
          </div>
          {role == 2 && <Button width={60} height={20} fontSize={10}
            title='Delete'
            className={classNames('button',)} onClick={() => onDelete(msg._id)} />}
        </div>
        <p className='body'>{msg.body}</p>

      </div>

    </div>
  )
}
export default Message

