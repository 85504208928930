import React from 'react';
import AppHeader from '../../components/Header';
import './style.scss';

const AppContainer = (props) => {
  const { children } = props
  return (
    <div className='app-main'>
      <AppHeader />
      <div>{children}</div>
    </div>
  )
}

export default AppContainer;