import React from 'react';
import './style.scss'
const Dropdown = (props) => {
  return (
    <div className="dropdown">
      <p className='label'>{props.label}</p>
      <select onChange={props.onChange} value={props.value}>
        {props.items.map((e, i) => <option key={i} value={e.value}>{e.text}</option>)}
      </select>
    </div>
  )
}
export default Dropdown;