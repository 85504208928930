import store from "../redux/store"
import { toastTypes, mainTypes } from "./types"
import { useDispatch } from 'react-redux'

const dispatchEvent = (type, payload) => ({ type, payload })

const openToast = (title, kind) => {
  store.dispatch(dispatchEvent(toastTypes.OPEN_TOAST, { title, kind }))
}

const successToast = title => {
  openToast(title, 'success')
}

const errorToast = title => [
  openToast(title, 'error')
]

const close = () => {
  store.dispatch(dispatchEvent(toastTypes.CLOSE_TOAST))
}

const setAdminRole = role => {
  return dispatchEvent(mainTypes.SET_ROLE, { role })
}
export default { successToast, errorToast, close, setAdminRole }