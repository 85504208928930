import React, { Component, useEffect, useState } from 'react';
import { getApi, postApi } from '../../apis/Apis';
import io from 'socket.io-client'
import queryString from 'query-string';
import ApiUrls from '../../apis/ApiUrls';
import News from '../News';
import Modal from '../Modal';
import './style.scss'
import CarModal from '../CarModal';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { IAdmins } from '../../models/IAdmins';
import { IconButton, } from '@material-ui/core';
import { FaPen, FaTrash } from 'react-icons/fa'
import AlertModal from '../AlertModal';
import moment from 'moment'
const Admins = (props) => {

  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)
  const [hId, setHId] = useState(null)
  const [admins, setAdmins] = useState([])
  // const [rankTool, setRankTool] = useState([false, 0, 0, null])
  // const [showDeleteModal, setShowDeleteModal] = useState(false)
  // var [editingStreamer, setEditingStreamer] = useState(new IAdmins())
  useEffect(() => {
    (async () => {
      setLoading(true)
      const res = await getApi(`${ApiUrls.getAdmins}`, true)
      setLoading(false)
      if (res.success) {
        setAdmins(res.result)
      }
    })()
  }, [])
  const toggleActive = async (id) => {
    console.log(id)
    var res = await postApi(`${ApiUrls.setAdminActive}`, { adminId: id }, true)
    if (res.success) {
      setAdmins(admins =>
        [...admins.map(e =>
          res.result._id == e._id ? res.result : e
        )]
      )
    }
  }

  const showHistory = (id) => {
    setHId(id)
  }
  // const onNew = () => {
  //   setShowModal(true)
  // }
  // const onEdit = (index) => {
  //   setShowModal(true)
  //   setEditingStreamer(new ICar(admins[index]))
  // }
  // const onDelete = (index) => {
  //   // setShowDeleteModal(true)
  //   // setEditingStreamer(new ICar(streamers[index]))
  // }

  // const setRank = async (rank) => {
  //   setRankTool([false, 0, 0])
  //   var res = await postApi(`${ApiUrls.setRank}`,
  //     { userId: rankTool[3], rank: rank }, true)
  //   if (res.success) {
  //     setStreamers((streamers) => {
  //       var newStreamers = streamers.map((e) => {
  //         if (res.result._id == e._id)
  //           return res.result
  //         if (res.changed) {
  //           if (e._id == res.changed._id)
  //             return res.changed
  //           return e
  //         }
  //         return e
  //       })
  //       return [...newStreamers]
  //     })

  //   }
  // }
  return (
    <>
      {loading ? <div style={{ width: '100%', height: '100%' }}>Loading</div> :
        <div>
          {/* <Button onClick={onNew} title='Add Streamer' /> */}
          <div className='admins'>
            {admins.map((e, i) => {

              return <div className='admin-row' key={i}
              >
                <div className='data-area flex align-center'>
                  {/* <img src={e.imgPath} /> */}
                  <div>
                    <p className='admin-email'>
                      {e.email}</p>
                    <div className='flex numbers'>
                      <p className='admin-role'>{e.role == 0 ? 'Master' : 'Normal'}</p>
                      <p className={`admin-active ${e.active ? 'active' : ''}`}>{e.active ? 'Active' : 'Disabled'}</p>
                      {/* <p className='full-name'>
                        {e.followings} <span className='comment'> Followings</span></p>
                      <p className='full-name'>
                        {e.candy} <span className='comment'> Candy</span></p>
                      <p className='full-name'>
                        {e.views} <span className='comment'> Viewers</span></p> */}
                    </div>
                    {/* <p className='caption'>{e.caption}</p> */}
                  </div>
                </div>
                <div className='flex align-center'>
                  {e.role > 0 && (
                    <>
                      <Button fontSize={12} width={96} height={28} backColor='white'
                        title={e.active ? 'Set Disable' : 'Set Active'}
                        borderColor='darkOrange'
                        textColor='darkOrange'
                        onClick={() => {
                          toggleActive(e._id)
                        }}
                      /><Button fontSize={12} width={96} height={28} backColor='white'
                        title='Show history'
                        borderColor='darkOrange'
                        textColor='darkOrange'
                        onClick={() => {
                          showHistory(e._id)
                        }}
                      />
                    </>)}
                  {/*
                  <Button title={e.live ? 'Make Offline' : 'Make Live'} fontSize={12}
                    width={96} height={28} backColor='white'
                    borderColor={e.live ? 'green' : 'pink'}
                    textColor={e.live ? 'green' : 'pink'}
                    onClick={() => {
                      setStreamers((streamers) => {
                        streamers[i].live = !streamers[i].live
                        return [...streamers]
                      })
                    }}
                  /> */}
                  {/* <IconButton children={<FaPen size={16} color='lightgrey' />} onClick={() => { onEdit(i) }} />
                  <IconButton children={<FaTrash size={16} color='lightgrey' />} onClick={() => { onDelete(i) }} /> */}
                </div>
              </div>
            })}
            {/* {rankTool[0] && <Tooltip show={rankTool[0]}
              x={rankTool[1]} y={rankTool[2]} >
              <div className='rank-tool'>
                <div className='item close' onClick={() => setRankTool([false, 0, 0])}>Close</div>
                <div className='item' onClick={() => setRank(1)}>Set Rank 1</div>
                <div className='item' onClick={() => setRank(2)}>Set Rank 2</div>
                <div className='item' onClick={() => setRank(3)}>Set Rank 3</div>
              </div></Tooltip>} */}
          </div>
        </div>
      }
      {/* {showDeleteModal && <AlertModal show={showDeleteModal} title='Alert'
        subTitle='Really delete this model?' onClose={() => {
          setShowDeleteModal(false);
        }} onConfirm={async () => {
          setShowDeleteModal(false)
          var res = await postApi(`${ApiUrls.deleteStreamer}`, { streamerId: editingStreamer._id }, true)
          if (res.success) {
            setStreamers((streamers) => [...streamers.map((e) =>
              e._id != editingStreamer._id && e)])
          }
        }} />}
        */}
      {hId && <Modal show={hId} height='calc(100vh - 80px)' width='calc(100vw - 120px)' title='History'
        onClose={() => {
          setHId(null)
        }}
        onConfirm={() => {
          setHId(null)
        }}
      >
        {hId && <History id={hId} />}
      </Modal>}
    </>
  )
}
const History = ({ id }) => {

  const [history, setHistory] = useState([])
  const [offset, setOffset] = useState(0)
  const timestamp = time => {
    var now = moment.now()
    const diff = moment().diff(moment(time), 'seconds')
    if (diff <= 60) return 'Just now'
    else if (diff < 3600) return `${Math.floor(diff / 60)}mins ago`
    else if (diff < 86400) return moment(time).format('hh:mm')
    else return moment(time).format('hh:mm, MMM DD YYYY')
  }
  const loadHistory = async () => {
    const res = await postApi(`${ApiUrls.getAdminHistory}`, { adminId: id, offset }, true)
    if (res.success) {
      setOffset(offset + res.result.length)
      setHistory([...history, ...res.result])
    }
  }
  useEffect(
    () => {
      loadHistory()
    }, [])
  return (
    <div className='history'>
      <div className='table'>
        {history.length == 0 ? <p>No History</p> :
          <div className=''>
            {history.map((e, i) =>
              <div key={i} className='flex h-item'>
                <p className='h-data'>{e.data}</p>
                <p className='h-time'>{timestamp(e.time)}</p>
              </div>)}
            <div className='flex justify-center mv-12'>
              <Button title='Load more' height='32' backColor='white' textColor='grey' borderColor='grey'
              onClick={loadHistory}
            /></div>
          </div>
        }
      </div>
    </div>
  )
}
export default Admins